import React from 'react';
import { t } from '../utils/labels';
import { FoodServiceDetailQuery } from '../graphql/query/FoodServiceDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/FoodService.json';
import { ManagedByUserDetail } from './ManagedByUserDetail';
import { ManagedByUserEdit } from './ManagedByUserEdit';
import { ManagedByUserCreate } from './ManagedByUserCreate';
import { ICompanyDetailProps } from './CompanyDetail';
import { ENTITY } from '../utils/entities';
import { transformerHandler } from '../utils/misc';
import { fastTransformer, refactorInfo } from '../utils/form';
import gql from 'graphql-tag';
import { UserData } from '@food/auth';
//import { StoreChainCreateModal } from './StoreChainCreate';

export const FoodServiceDetail: React.FC<ICompanyDetailProps> = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityDetailFetcher
				query={FoodServiceDetailQuery}
				entityName={ENTITY.FOOD_SERVICE}
				id={id}
				typeIntro={typeIntro}
				clonable={false}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'vatNumber',
					'dimension',
					'type',
					'slug',
					'logoMedia',
					'billingName',
					'description',
					'descriptionIta',
					'descriptionLong',
					'descriptionLongIta',
					'pecEmail',
					'website',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
					'managedByUsers',
					//'storeChains'
				]}
				transformer={transformerHandler(
					fastTransformer({
						managedByUsers: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}

							f.props.headers = ['User', 'capabilities'];
							f.editComponent = ManagedByUserEdit;
							f.detailComponent = ManagedByUserDetail;
							f.addComponent = ManagedByUserCreate;
							f.tab = 1;
							f.props.fragment = gql`
								fragment Fragment on ManagedByUser {
									User {
										id
										name
									}
									capabilities
								}
							`;

							return f;
						},
						/*
						storeChains: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							f.props.headers = [
								'name',
							];
							f.addComponent = StoreChainCreateModal;
							f.tab = 2;
							f.props.fragment = gql`
								fragment Fragment on StoreChain {
									name
								}
							`;
							return f;
						},
						*/
					}),
				)}
				tabs={[
					t('generic data'),
					t(['FoodService', 'managedByUsers', 'label'].join('/')),
					//t([ENTITY.STORECHAIN, 'label'].join('/')),
				]}
				//cols={[['col-sm-12'], ['col-sm-12'], ['col-sm-12']]}
				cols={[['col-sm-12'], ['col-sm-12']]}
			/>
		)}
	</UserData>
);
