import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createMenu } from '../static/menu';
import { Sidebar } from '../components/Sidebar';
import { Footer } from '../components/Footer';
import { Navbar } from '../components/Navbar';
import { Spinner } from '../components/MainContentSpinner';
import { Dashboard } from './Dashboard';
import { CompanyList } from './CompanyList';
import { GoodList } from './GoodList';
import { CandidacyList } from './CandidacyList';
import { UserList } from './UserList';
import { AwardList } from './AwardList';
// import { BannerList } from './BannerList';
import { CompanyEdit } from './CompanyEdit';
import { GoodEdit } from './GoodEdit';
import { CandidacyEdit } from './CandidacyEdit';
import { UserEdit } from './UserEdit';
import { AwardEdit } from './AwardEdit';
// import { BannerEdit } from './BannerEdit';
import { AwardCreate } from './AwardCreate';
import { CompanyCreate } from './CompanyCreate';
import { GoodCreate } from './GoodCreate';
import { CandidacyCreate } from './CandidacyCreate';
import { UserCreate } from './UserCreate';
// import { BannerCreate } from './BannerCreate';
import { CompanyDetail } from './CompanyDetail';
import { GoodDetail } from './GoodDetail';
import { CandidacyDetail } from './CandidacyDetail';
import { UserDetail } from './UserDetail';
import { AwardDetail } from './AwardDetail';
import { ErrorModal } from '../components/ErrorModal';
import { setErrorModal } from '../utils/errors';
// import { BannerDetail } from './BannerDetail';
// import { WidgetList } from './WidgetList';
// import { WidgetCreate } from './WidgetCreate';
// import { WidgetEdit } from './WidgetEdit';
// import { WidgetDetail } from './WidgetDetail';
import { EditorialSectionList } from './EditorialSectionList';
import { EditorialSectionCreate } from './EditorialSectionCreate';
import { EditorialSectionEdit } from './EditorialSectionEdit';
import { EditorialSectionDetail } from './EditorialSectionDetail';
import { NotFoundComponent } from '../components/404';
import { RetailerCreate } from './RetailerCreate';
import { RetailerDetail } from './RetailerDetail';
import { RetailerList } from './RetailerList';
import { RetailerEdit } from './RetailerEdit';
import { TeamList } from './TeamList';
import { TeamDetail } from './TeamDetail';
import { TeamCreate } from './TeamCreate';
import { TeamEdit } from './TeamEdit';
import { EmailDomainList } from './EmailDomainList';
import { EmailDomainDetail } from './EmailDomainDetail';
import { EmailDomainCreate } from './EmailDomainCreate';
import { EmailDomainEdit } from './EmailDomainEdit';
import { FairList } from './FairList';
import { FairDetail } from './FairDetail';
import { FairCreate } from './FairCreate';
import { FairEdit } from './FairEdit';
import { GeoIndicationList } from './GeoIndicationList';
import { GeoIndicationDetail } from './GeoIndicationDetail';
import { GeoIndicationCreate } from './GeoIndicationCreate';
import { GeoIndicationEdit } from './GeoIndicationEdit';
import { CompanyGroupCreate } from './CompanyGroupCreate';
import { CompanyGroupList } from './CompanyGroupList';
import { CompanyGroupDetail } from './CompanyGroupDetail';
import { CompanyGroupEdit } from './CompanyGroupEdit';
// import { HtmlTemplateList } from './HtmlTemplateList';
// import { HtmlTemplateDetail } from './HtmlTemplateDetail';
// import { HtmlTemplateCreate } from './HtmlTemplateCreate';
// import { HtmlTemplateEdit } from './HtmlTemplateEdit';
import { EuCategoryList } from './EuCategoryList';
import { EuCategoryDetail } from './EuCategoryDetail';
import { EuCategoryCreate } from './EuCategoryCreate';
import { EuCategoryEdit } from './EuCategoryEdit';
import { ServiceList } from './ServiceList';
import { ServiceDetail } from './ServiceDetail';
import { ServiceCreate } from './ServiceCreate';
import { ServiceEdit } from './ServiceEdit';
// import { FoodServiceList } from './FoodServiceList';
// import { FoodServiceCreate } from './FoodServiceCreate';
// import { FoodServiceDetail } from './FoodServiceDetail';
// import { FoodServiceEdit } from './FoodServiceEdit';
import { EditorialTagList } from './EditorialTagList';
import { EditorialTagDetail } from './EditorialTagDetail';
import { EditorialTagCreate } from './EditorialTagCreate';
import { EditorialTagEdit } from './EditorialTagEdit';
import { ObjectHistoryDetail } from './ObjectHistoryDetail';
import { ObjectHistoryList } from './ObjectHistoryList';
import { Award_producer_List } from './Award_producer_List';
import { ToSList } from './ToSList';
import { ToSDetail } from './ToSDetail';
import { ToSCreate } from './ToSCreate';
import { ToSEdit } from './ToSEdit';
import { QuestionSetList } from './QuestionSetList';
import { QuestionSetDetail } from './QuestionSetDetail';
import { AnswerSetList } from './AnswerSetList';
import { AnswerSetDetail } from './AnswerSetDetail';
import { AnswerSetEdit } from './AnswerSetEdit';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import { FrequentSearchList } from './FrequentSearchList';
import { FrequentSearchDetail } from './FrequentSearchDetail';
import { FrequentSearchCreate } from './FrequentSearchCreate';
import { FrequentSearchEdit } from './FrequentSearchEdit';
// import { FairVisitList } from './FairVisitList';
// import { FairVisitDetail } from './FairVisitDetail';
// import { FairVisitCreate } from './FairVisitCreate';
// import { FairVisitEdit } from './FairVisitEdit';
import { getUserData, isLogged, UserData } from '@food/auth';
import { ReportConfigurator } from './fields/ReportConfigurator';
import { ReportList } from '../components/ReportList';
import { CompanyBadgeList } from './CompanyBadgeList';
import { CompanyBadgeDetail } from './CompanyBadgeDetail';
import { CompanyBadgeCreate } from './CompanyBadgeCreate';
import { CompanyBadgeEdit } from './CompanyBadgeEdit';
import { GoodClassCreate } from './GoodClassCreate';
import { GoodClassList } from './GoodClassList';
import { GoodClassDetail } from './GoodClassDetail';
import { GoodClassEdit } from './GoodClassEdit';
import { CompanyCertificationList } from './CompanyCertificationList';
import { CompanyCertificationDetail } from './CompanyCertificationDetail';
import { CompanyCertificationCreate } from './CompanyCertificationCreate';
import { CompanyCertificationEdit } from './CompanyCertificationEdit';
import { GoodFeatureCertificationList } from './GoodFeatureCertificationList';
import { GoodFeatureCertificationDetail } from './GoodFeatureCertificationDetail';
import { GoodFeatureCertificationCreate } from './GoodFeatureCertificationCreate';
import { GoodFeatureCertificationEdit } from './GoodFeatureCertificationEdit';
import { IFNPage } from '../components/pages/IFN';
import { ChecklistAward } from '../components/pages/ChecklistAward';
import { Faq } from '../components/pages/Faq';
import { ChecklistPlatform } from '../components/pages/ChecklistPlatform';
import { NewsletterCreate } from './NewsletterCreate';
import { NewsletterList } from './NewsletterList';
import { NewsletterDetail } from './NewsletterDetail';
import { NewsletterEdit } from './NewsletterEdit';
import { NewsletterIssueList } from './NewsletterIssueList';
import { NewsletterIssueCreate } from './NewsletterIssueCreate';
import { NewsletterIssueDetail } from './NewsletterIssueDetail';
import { NewsletterIssueEdit } from './NewsletterIssueEdit';
import { MagazineCollectionList } from './MagazineCollectionList';
import { MagazineCollectionCreate } from './MagazineCollectionCreate';
import { MagazineCollectionDetail } from './MagazineCollectionDetail';
import { MagazineCollectionEdit } from './MagazineCollectionEdit';
import { MagazineList } from './MagazineList';
import { MagazineCreate } from './MagazineCreate';
import { MagazineDetail } from './MagazineDetail';
import { MagazineEdit } from './MagazineEdit';
import { StaticFrequentSearchCreate } from './StaticFrequentSearchCreate';
import { StaticFrequentSearchDetail } from './StaticFrequentSearchDetail';
import { StaticFrequentSearchEdit } from './StaticFrequentSearchEdit';
import { GraphiqlComponent } from '../components/Graphiql';
import { MediaList } from './MediaList';
import { UserRole } from '../server-types';
import { CouponList } from './CouponList';
import { CouponEdit } from './CouponEdit';
import { CouponCreate } from './CouponCreate';
import { CouponDetail } from './CouponDetail';
import { FoodServiceList } from './FoodServiceList';
import { FoodServiceDetail } from './FoodServiceDetail';
import { FoodServiceCreate } from './FoodServiceCreate';
import { FoodServiceEdit } from './FoodServiceEdit';
import { ObjectsMergeComponent } from 'components/ObjectsMergeComponent';
// import { UnderConstruction } from '../components/pages/UnderConstruction';
import { DirectoryReloadComponent } from '../components/DirectoryReloadComponent';
import { UpdateIfnStatsComponent } from 'components/UpdateIfnStatsComponent';

import { DataVizList } from './DataVizList';
import { DataVizCreate } from './DataVizCreate';
import { DataVizDetail } from './DataVizDetail';
import { DataVizEdit } from './DataVizEdit';

import { GoodRecallList } from './GoodRecallList';
import { GoodRecallCreate } from './GoodRecallCreate';
import { GoodRecallDetail } from './GoodRecallDetail';
import { GoodRecallEdit } from './GoodRecallEdit';

import { PLManufacturerList } from './PLManufacturerList';

import { StoreChainList } from './StoreChainList';
import { StoreChainCreate } from './StoreChainCreate';
import { StoreChainDetail } from './StoreChainDetail';


const entities: any[] = [
	{
		path: '/awards',
		edit: AwardEdit,
		create: AwardCreate,
		list: AwardList,
		detail: AwardDetail,
	},
	{
		path: '/companies',
		edit: CompanyEdit,
		create: CompanyCreate,
		list: CompanyList,
		detail: CompanyDetail,
	},
	{
		path: '/users',
		edit: UserEdit,
		create: UserCreate,
		list: UserList,
		detail: UserDetail,
	},
	{
		path: '/goods',
		edit: GoodEdit,
		create: GoodCreate,
		list: GoodList,
		detail: GoodDetail,
	},
	{
		path: '/candidacies',
		edit: CandidacyEdit,
		create: CandidacyCreate,
		list: CandidacyList,
		detail: CandidacyDetail,
	},
	// {
	// 	path: '/banners',
	// 	create: BannerCreate,
	// 	list: BannerList,
	// 	detail: BannerDetail,
	// 	edit: BannerEdit,
	// },
	// {
	// 	path: '/widgets',
	// 	create: WidgetCreate,
	// 	list: WidgetList,
	// 	detail: WidgetDetail,
	// 	edit: WidgetEdit,
	// },
	{
		path: '/editorialsections',
		create: EditorialSectionCreate,
		list: EditorialSectionList,
		detail: EditorialSectionDetail,
		edit: EditorialSectionEdit,
	},
	{
		path: '/retailers',
		list: RetailerList,
		detail: RetailerDetail,
		create: RetailerCreate,
		edit: RetailerEdit,
	},
	/*{
		path: '/news',
		list: NewsList,
		detail: NewsDetail,
	},*/
	{
		path: '/emaildomains',
		list: EmailDomainList,
		detail: EmailDomainDetail,
		create: EmailDomainCreate,
		edit: EmailDomainEdit,
	},
	{
		path: '/fairs',
		list: FairList,
		detail: FairDetail,
		create: FairCreate,
		edit: FairEdit,
	},
	{
		path: '/geoindications',
		list: GeoIndicationList,
		detail: GeoIndicationDetail,
		create: GeoIndicationCreate,
		edit: GeoIndicationEdit,
	},
	{
		path: '/teams',
		list: TeamList,
		detail: TeamDetail,
		create: TeamCreate,
		edit: TeamEdit,
	},
	{
		path: '/companygroups',
		list: CompanyGroupList,
		detail: CompanyGroupDetail,
		create: CompanyGroupCreate,
		edit: CompanyGroupEdit,
	},
	// {
	// 	path: '/htmltemplates',
	// 	list: HtmlTemplateList,
	// 	detail: HtmlTemplateDetail,
	// 	create: HtmlTemplateCreate,
	// 	edit: HtmlTemplateEdit,
	// },
	{
		path: '/eucategories',
		list: EuCategoryList,
		detail: EuCategoryDetail,
		create: EuCategoryCreate,
		edit: EuCategoryEdit,
	},
	{
		path: '/services',
		list: ServiceList,
		detail: ServiceDetail,
		create: ServiceCreate,
		edit: ServiceEdit,
	},
	{
		path: '/foodservices',
		list: FoodServiceList,
		detail: FoodServiceDetail,
		create: FoodServiceCreate,
		edit: FoodServiceEdit,
	},
	{
		path: '/editorialtags',
		list: EditorialTagList,
		detail: EditorialTagDetail,
		create: EditorialTagCreate,
		edit: EditorialTagEdit,
	},
	{
		path: '/objecthistories',
		list: ObjectHistoryList,
		detail: ObjectHistoryDetail,
	},
	{
		path: '/toss',
		list: ToSList,
		detail: ToSDetail,
		create: ToSCreate,
		edit: ToSEdit,
	},
	{
		path: '/questionsets',
		list: QuestionSetList,
		detail: QuestionSetDetail,
		edit: () => <NotFoundComponent />,
		create: () => <NotFoundComponent />,
	},
	{
		path: '/answersets',
		list: AnswerSetList,
		detail: AnswerSetDetail,
		edit: AnswerSetEdit,
	},
	{
		path: generateUrl(ENTITY.FREQUENT_SEARCH, ACTIONS.LIST),
		list: FrequentSearchList,
		detail: FrequentSearchDetail,
		create: FrequentSearchCreate,
		edit: FrequentSearchEdit,
	},
	// {
	// 	path: generateUrl(ENTITY.FAIR_VISIT, ACTIONS.LIST),
	// 	list: FairVisitList,
	// 	detail: FairVisitDetail,
	// 	create: FairVisitCreate,
	// 	edit: FairVisitEdit,
	// },
	{
		path: generateUrl(ENTITY.COMPANY_BADGE, ACTIONS.LIST),
		list: CompanyBadgeList,
		detail: CompanyBadgeDetail,
		create: CompanyBadgeCreate,
		edit: CompanyBadgeEdit,
	},
	{
		path: generateUrl(ENTITY.GOOD_CLASS, ACTIONS.LIST),
		list: GoodClassList,
		detail: GoodClassDetail,
		create: GoodClassCreate,
		edit: GoodClassEdit,
	},
	{
		path: generateUrl(ENTITY.COMPANY_CERTIFICATION, ACTIONS.LIST),
		list: CompanyCertificationList,
		detail: CompanyCertificationDetail,
		create: CompanyCertificationCreate,
		edit: CompanyCertificationEdit,
	},
	{
		path: generateUrl(ENTITY.GOOD_FEATURE_CERTIFICATION, ACTIONS.LIST),
		list: GoodFeatureCertificationList,
		detail: GoodFeatureCertificationDetail,
		create: GoodFeatureCertificationCreate,
		edit: GoodFeatureCertificationEdit,
	},
	{
		path: generateUrl(ENTITY.NEWSLETTER, ACTIONS.LIST),
		list: NewsletterList,
		create: NewsletterCreate,
		detail: NewsletterDetail,
		edit: NewsletterEdit,
	},
	{
		path: generateUrl(ENTITY.NEWSLETTER_ISSUE, ACTIONS.LIST),
		list: NewsletterIssueList,
		create: NewsletterIssueCreate,
		detail: NewsletterIssueDetail,
		edit: NewsletterIssueEdit,
	},
	{
		path: generateUrl(ENTITY.MAGAZINE_COLLECTION, ACTIONS.LIST),
		list: MagazineCollectionList,
		create: MagazineCollectionCreate,
		detail: MagazineCollectionDetail,
		edit: MagazineCollectionEdit,
	},
	{
		path: generateUrl(ENTITY.MAGAZINE, ACTIONS.LIST),
		list: MagazineList,
		create: MagazineCreate,
		detail: MagazineDetail,
		edit: MagazineEdit,
	} /*
	{
		path: generateUrl(ENTITY.TYPEFORM, ACTIONS.LIST),
		list: TypeformList,
		create: TypeformCreate,
		detail: TypeformDetail,
		edit: TypeformEdit,
	},*/,
	{
		path: generateUrl(ENTITY.DATA_VIZ, ACTIONS.LIST),
		list: DataVizList,
		create: DataVizCreate,
		detail: DataVizDetail,
		edit: DataVizEdit,
	},
	{
		path: generateUrl(ENTITY.MEDIA, ACTIONS.LIST),
		list: MediaList,
		edit: () => <NotFoundComponent />,
		create: () => <NotFoundComponent />,
		detail: () => <NotFoundComponent />,
	},
	{
		path: generateUrl(ENTITY.COUPON, ACTIONS.LIST),
		list: CouponList,
		edit: CouponEdit,
		create: CouponCreate,
		detail: CouponDetail,
	},
	{
		path: generateUrl(ENTITY.GOOD_RECALL, ACTIONS.LIST),
		list: GoodRecallList,
		create: GoodRecallCreate,
		detail: GoodRecallDetail,
		edit: GoodRecallEdit,
	},
	{
		path: generateUrl(ENTITY.PLMANUFACTURER, ACTIONS.LIST),
		list: PLManufacturerList
	},
	{
		path: generateUrl(ENTITY.STORECHAIN, ACTIONS.LIST),
		list: StoreChainList,
		create: StoreChainCreate,
		detail: StoreChainDetail,
	},
];

export const LoggedLayout: React.FC = () => {
	const errorRef = React.useRef(null);

	React.useEffect(() => {
		setErrorModal(errorRef);
		window.scrollTo(0, 0);
	}, []);

	const user = getUserData();
	if (user && user.role === UserRole.Consumer) {
		return <Redirect to={'/consumer-welcome'} />;
	}

	return (
		<Route>
			{({ location }) => (
				<>
					<ErrorModal ref={errorRef} />
					<UserData>
						{(user) => {
							if (!isLogged()) {
								return (
									<Redirect
										to={'/login?redirect=' + location.pathname}
									/>
								);
							}

							if (user.loading || user.userData === undefined) {
								return <Spinner show={true} />;
							}

							const additionalClass = location.pathname.includes(
								'graphiql',
							)
								? 'graphiql'
								: '';

							return (
								<>
									<Sidebar menu={createMenu(user)} />
									<Navbar />
									<div className={'page-wrapper ' + additionalClass}>
										<div className="container-fluid">
											<Switch>
												<Route
													path="/"
													exact
													component={Dashboard}
												/>
												{/* ALCUNI CASI SPECIALI */}
												<Route
													path={
														generateUrl(
															ENTITY.FREQUENT_SEARCH,
															ACTIONS.LIST,
														) + '/static/new'
													}
													exact
													component={StaticFrequentSearchCreate}
												/>
												<Route
													exact
													path={
														generateUrl(
															ENTITY.FREQUENT_SEARCH,
															ACTIONS.LIST,
														) + '/static/:id'
													}
													component={({ match: { params } }) => (
														<StaticFrequentSearchDetail
															id={decodeURIComponent(params.id)}
														/>
													)}
												/>
												<Route
													exact
													path={
														generateUrl(
															ENTITY.FREQUENT_SEARCH,
															ACTIONS.LIST,
														) + '/static/:id/edit'
													}
													component={({ match: { params } }) => (
														<StaticFrequentSearchEdit
															id={decodeURIComponent(params.id)}
														/>
													)}
												/>
												{/* ==================================================== */}
												{/* Entities lists */}
												{entities.map((Entity) => (
													<Route
														key={'list_' + Entity.path}
														path={Entity.path}
														exact
														component={Entity.list}
													/>
												))}
												{/* Entities creation forms */}
												{entities.map((Entity) => (
													<Route
														key={'creation_' + Entity.path}
														path={Entity.path + '/new'}
														component={Entity.create}
													/>
												))}
												{/* Entities details*/}
												{entities.map((Entity) => (
													<Route
														key={'detail_' + Entity.path}
														exact
														path={Entity.path + '/:id'}
														component={({
															match: { params },
														}) => (
															<Entity.detail
																id={decodeURIComponent(
																	params.id,
																)}
															/>
														)}
													/>
												))}
												{/* Entites edit forms */}
												{entities.map((Entity) => (
													<Route
														key={'edit_' + Entity.path}
														path={Entity.path + '/:id/edit'}
														component={({
															match: { params },
														}) => (
															<Entity.edit
																id={decodeURIComponent(
																	params.id,
																)}
															/>
														)}
													/>
												))}
												{/* Entities clonation forms */}
												{entities.map((Entity) => (
													<Route
														key={'clone_' + Entity.path}
														path={Entity.path + '/:id/clone'}
														component={({
															match: { params },
														}) => (
															<Entity.create
																id={decodeURIComponent(
																	params.id,
																)}
															/>
														)}
													/>
												))}
												<Route
													path="/awards-producer-public"
													component={() => (
														<Award_producer_List
															visibility={'VOTE'}
														/>
													)}
												/>
												<Route
													path="/awards-producer-private"
													component={() => (
														<Award_producer_List
															visibility={'SHOWCASE'}
														/>
													)}
												/>
												<Route
													path="/awards-producer-sourcing"
													component={() => (
														<Award_producer_List
															visibility={'SOURCING'}
														/>
													)}
												/>
												<Route
													path="/reports/:id"
													component={ReportConfigurator}
												/>
												<Route
													path="/reports"
													component={ReportList}
												/>
												<Route
													path="/platforms/:platform"
													exact
													component={({ match: { params } }) => (
														<IFNPage
															platform={params.platform.toUpperCase()}
														/>
													)}
												/>
												<Route
													path="/awards/:id/checklist"
													exact
													component={({ match: { params } }) => (
														<ChecklistAward awardId={params.id} />
													)}
												/>
												<Route
													path="/platforms/:platform/checklist"
													exact
													component={({ match: { params } }) => (
														<ChecklistPlatform
															platform={params.platform.toUpperCase()}
														/>
													)}
												/>
												<Route path="/faq" exact component={Faq} />
												<Route
													path={'/graphiql'}
													exact
													component={GraphiqlComponent}
												/>
												<Route
													path={'/objects-merge'}
													exact
													component={ObjectsMergeComponent}
												/>
												<Route
													path={'/directory-reload'}
													exact
													component={DirectoryReloadComponent}
												/>
												<Route
													path={'/update-ifn-stats'}
													exact
													component={UpdateIfnStatsComponent}
												/>
												<Route component={NotFoundComponent} />
											</Switch>
										</div>
									</div>
									<Footer />
								</>
							);
						}}
					</UserData>
				</>
			)}
		</Route>
	);
};
