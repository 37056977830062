import gql from 'graphql-tag';
import { StoreChainFullFragment } from 'graphql/fragments/StoreChainFullFragment';

export const StoreChainDetailQuery = gql`
	query StoreChainDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...StoreChainFullFragment
		}
	}
	${StoreChainFullFragment}
`;